import {Routes, Route} from 'react-router-dom';	//, redirect
import React, { useEffect, useState } from 'react';
import axios from 'axios'

import './App.css';

import Home from './pages/Home'
import Webservices from './pages/Webservices'
import Team from './pages/Team'
import Portfolio from './pages/Portfolio'
import Login from './pages/Login'
import Contact from './pages/Contact'

import Navbar from './components/Navbar'


function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function App() {
  //Get base URL to swap between Dev and Prod environments
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
	
  const [authResult, setAuthResult] = useState(null);
  const [currentImage, setCurrentImage] = useState(null); // No default image

  useEffect(() => {
	  
    const checkCookie = async () => {
		
		let cairnAuth = null;
		  if (document.cookie.indexOf('cairnAuth') > -1) {
			cairnAuth = getCookie('cairnAuth');
		  }
	  	  
		try {
			const response = await axios.post(`${API_BASE_URL}/checkcookie`, {
			  cookieVal: cairnAuth, // Ensure cairnAuth is defined or passed as a prop/context
			});
			console.log(response.data.result)
			setAuthResult(response.data.result);
		} catch (error) {
			process.env.DEBUG_FLAG === 'True' && console.error('Error checking cookie:', error);
			setAuthResult(false);
		}
    };

    checkCookie();
  }, []);

  if (authResult === null) {
    return <div>Loading...</div>; // Show a loading state while waiting for the response
  } else {
    return (
		<div className="App">
		<Navbar />
		<main className="flex-grow">
			 <Routes>
				<Route path="/" element={<Home currentImage={currentImage} setCurrentImage={setCurrentImage} />} />
				<Route path="/login" element={<Login currentImage={currentImage} setCurrentImage={setCurrentImage} />} />
				<Route path="/team" element={<Team currentImage={currentImage} setCurrentImage={setCurrentImage} />} />
				<Route path='/portfolio' element= {<Portfolio currentImage={currentImage} setCurrentImage={setCurrentImage} />} />
				<Route path='/contact' element= {<Contact currentImage={currentImage} setCurrentImage={setCurrentImage} />} />
				<Route path='/webservices' element= {<Webservices />} />
			
			 </Routes>
		  </main>
		 <footer className="w-full">
			<div className="flex flex-col w-full min-h-[5vh] items-center justify-center bg-slate-500 px-4 text-white text-sm italic md:flex-row md:justify-between">
			  &copy; 2024 | Full-stack development by Gerrit Verbeek
			</div>
			<div className="invisible bg-blue-100 bg-blue-600 bg-blue-800"></div>
		  </footer>
		</div>
	  );

    //return <redirect to="/home" />;
	//alert('Not Signed In')
  }
}

export default App;
