import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccordionComponent from '../components/Accordion';

function Webservices() {
  return (

	<div className='w-full min-h-[83vh] md:min-h-[75vh] flex flex-col items-center text-center p-10 justify-top pt-24 bg-gray-100'>
		<span className="text-3xl italic">Coming Soon</span>
		<span className="pt-6 text-lg italic">A set of in-browser tools offering the output of a skilled technical workforce with the cost-savings of automation. </span>
    </div>
  );
}

export default Webservices;