import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import AccordionComponent from '../components/Accordion';
import Frame from '../components/Frame';



function Contact({ currentImage, setCurrentImage }) {
	//Get base URL to swap between Dev and Prod environments
//	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//	process.env.DEBUG_FLAG === 'True' && console.log(`${API_BASE_URL}`); //Debug mode commenting

	const [formData, setFormData] = useState({
    name: '',
    contactInfo: '',
    request: '',
    preferredMethod: 'email',
  });
  
    const [openFramePath, setOpenFramePath] = useState(null);
	
  // Function to open a frame based on its path
  const openFrame = (path) => (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setOpenFramePath(path);
  };

  // Function to close the currently open frame
  const closeFrame = () => setOpenFramePath(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

//  const handleSubmit = async (event) => {
//		event.preventDefault()
//		process.env.DEBUG_FLAG === 'True' && console.log(formData);
//		//try {
//		
//		const response = await axios.post(`${API_BASE_URL}/contactform`, {
//			dataArray: formData
//		})
//
//	}
  
  return (
		<div className='w-full h-full min-h-[83vh] md:min-h-[75vh] flex flex-grow relative'>
		  <div className='w-[30%] active:w-[50%] bg-blue-100 text-black flex flex-col items-center text-center pb-6'>
			<img
			  src="/_sidebar_climbing.jpg"
			  alt="Rappeling a Chugach peak"
			  className="border border-slate-700 max-w-xs mt-4"
			/>
		  </div>
			<div className='min-w-[70%] flex-grow flex-basis-[70%] bg-slate-200 text-black flex flex-col items-center text-center pb-6'>
				<span className="p-6 text-center">Whether you have an objective in mind or just want to talk shop, drop us a line!<br/>We enjoy exploring...</span>
				{/*<form onSubmit={handleSubmit} className="w-4/5 mx-auto p-6 border border-slate-300 rounded-lg text-left flex flex-col items-center"> */}
				<form name="contact" className="w-11/12 md:w-4/5 mx-auto p-6 border border-slate-300 rounded-lg text-left flex flex-col items-center"  method="POST" data-netlify="true" netlify-honeypot="bot-field">
				
					{/* Required for Netlify serverless forms */}
					<input type="hidden" name="form-name" value ="contact" />
					{/* Required for Netlify serverless forms */}
				
				  <div className="mb-4 w-full">
					<label htmlFor="name" className="block text-sm font-medium mb-2">Name</label>
					<input
					  type="text"
					  id="name"
					  name="name"
					  value={formData.name}
					  onChange={handleChange}
					  className="w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					  required
					/>
				  </div>

				  <div className="mb-4 w-full">
					<label htmlFor="contactInfo" className="block text-sm font-medium mb-2">Contact Info</label>
					<input
					  type="text"
					  id="contactInfo"
					  name="contactInfo"
					  value={formData.contactInfo}
					  onChange={handleChange}
					  className="w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					  required
					/>
				  </div>

				  <div className="mb-4 w-full">
					<label htmlFor="preferredMethod" className="block text-sm font-medium mb-2">Preferred Communication Method</label>
					<select
					  id="preferredMethod"
					  name="preferredMethod"
					  value={formData.preferredMethod}
					  onChange={handleChange}
					  className="w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					>
					  <option value="email">Email</option>
					  <option value="phone">Phone</option>
					  <option value="in-person">In-Person</option>
					</select>
				  </div>

				  <div className="mb-4 w-full">
					<label htmlFor="request" className="block text-sm font-medium mb-2">Request</label>
					<textarea
					  id="request"
					  name="request"
					  value={formData.request}
					  onChange={handleChange}
					  className="w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-32"
					  required
					/>
				  </div>

				  <button
					type="submit"
					className="inline-block md:block md:w-2/5 p-3 bg-blue-500 hover:bg-blue-600 rounded text-white font-semibold"
				  >
					Submit
				  </button>
				</form>
			</div>
	    {openFramePath && (
			<Frame
			  htmlPath={openFramePath}
			  isOpen={!!openFramePath}
			  onClose={closeFrame}
			/>
		  )}
		</div>
	)

};

export default Contact;
