import React, { useState } from 'react';

const Frame = ({ htmlPath, isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
          <div className="relative w-11/12 h-5/6 bg-white rounded-lg shadow-lg overflow-hidden">
            <iframe
              src={htmlPath}
              title="Content Frame"
              className="w-full h-full"
            ></iframe>
            <button
              onClick={onClose}
              className="absolute top-2 right-2 bg-red-500 text-white rounded p-2 hover:bg-red-600"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Frame;