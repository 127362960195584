//export function updateURLParameter(param, value) {
//  const url = new URL(window.location);
//  url.searchParams.set(param, value);
//  window.history.replaceState({}, '', url);
//}

//export function updateURLParameter(param, value) {
//  const url = new URL(window.location.href);
//  const searchParams = new URLSearchParams(url.search);
//
//  // Update the searchParams object directly
//  searchParams.set(param, value);
//
//  // Construct the new URL with the updated search parameters
//  url.search = searchParams.toString();
//
//  // Replace the current history state with the updated URL
//  window.history.replaceState({}, '', url.href);
//}

export function updateURLParameter(param, value) {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  // Update the searchParams object directly
  searchParams.set(param, value);

  // Construct the new URL with the updated search parameters, ensuring commas are not encoded
  const newSearchParams = searchParams.toString().replace(/%2C/g, ',');

  // Replace the current history state with the updated URL
  window.history.replaceState({}, '', `${url.pathname}?${newSearchParams}`);
}