//import React, { useRef, useEffect, useState } from 'react';
import React, { useEffect, useState, useRef } from 'react';

const Home = ({ currentImage, setCurrentImage }) => {
	
	return (
		<div className='w-full h-full min-h-[83vh] md:min-h-[75vh] flex flex-grow relative'>
		  <div className='w-[30%] bg-blue-100 text-black flex flex-col items-center text-center pb-6'>
			<img
			  src="/_sidebar_aerial.png"
			  alt="[Missing image: Timelapse aerial imagery]"
			  className="max-w-xs mt-4"
			/>
		  </div>
		  <div className='w-[70%] bg-slate-200 text-black flex flex-grow flex-col items-center text-center pb-6'>
			<div className='w-3/4 mt-8'>
				Blue Mug exists to help solve complex digital problems, to free people from tedious, repetitive tasks, and to help organizations to punch far above their weight class and accomplish big things.<br/><br/>We excel at technical communication, data analysis, GIS, and automation of tasks using Python and VBA scripting in a Windows environment. Target partners are small- and medium-sized organizations with projects best solved by fully leveraging the software which they already utilize, along with free and open-source software and data sources.<br/><br/>Blue Mug is proudly headquartered in Anchorage, Alaska, but capable of assisting across the United States.
			</div>	
		  </div>
		</div>
	)
};

export default Home;

