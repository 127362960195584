import React, { useEffect, useState, useRef  } from 'react';
import axios from 'axios'
import Frame from '../components/Frame';

function Login({ currentImage, setCurrentImage }) {
	//Get base URL to swap between Dev and Prod environments
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
	
	const [email, setEmail] = useState('')
	const [pageOpacity, setPageOpacity] = useState(0); // For the new image
	const [overlayOpacity, setOverlayOpacity] = useState(1); // For the currentImage overlay
	const hasMounted = useRef(false); // Track if the component has mounted
    const [openFramePath, setOpenFramePath] = useState(null);

	

  // Function to open a frame based on its path
  const openFrame = (path) => (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setOpenFramePath(path);
  };

  // Function to close the currently open frame
  const closeFrame = () => setOpenFramePath(null);

  useEffect(() => {
    if (hasMounted.current) {
      // This will execute only if the component has already mounted
      setOverlayOpacity(0); // Start fading out the current image
      setPageOpacity(1); // Fade in the new image
    } else {
      // Mark as mounted
      hasMounted.current = true;
      // Set the initial image after the component has mounted
      setCurrentImage('/Example.jpg');
    }
  }, [currentImage, setCurrentImage]);
	
//	const handleLogin = async (event) => {
//		event.preventDefault()
//
//		try {
//			const response = await axios.post(`${API_BASE_URL}/login`, {
//				email: email
//			})
//
//
//			setEmail('')
//
//		} catch (error) {
//			
//			//Commented out to obscure error handling / email sniffing
//		  if (error.response) {
//			// The request was made and the server responded with a status code
//			// that falls out of the range of 2xx
//			console.error('Response error:', error.response.data);
//			console.error('Status:', error.response.status);
//			console.error('Headers:', error.response.headers);
//		  } else if (error.request) {
//			// The request was made but no response was received
//			console.error('No response received:', error.request);
//		  } else {
//			// Something happened in setting up the request that triggered an Error
//			console.error('Error in setting up request:', error.message);
//		  }
//		  console.error('Config:', error.config);
//		}
//	}
		
	return (
		<div className='w-full h-full min-h-[83vh] md:min-h-[75vh] flex flex-grow relative'>
		  <div className='w-[30%] bg-blue-100 text-black flex flex-col items-center text-center pb-6'>
			<img
			  src="/_sidebar_ocr.png"
			  alt="Example of data extraction using OCR"
			  className="border border-slate-700 max-w-xs mt-4"
			/>
		  </div>
		  <div className='min-w-[70%] flex-grow flex-basis-[70%] bg-slate-200 text-black flex flex-col items-center text-center pb-6'>
			<span className="p-6 text-center">To log in, submit your account email. You will receive a log-in link in your inbox.</span>
			{/*<form onSubmit={handleLogin} className="w-4/5 mx-auto p-6 border border-slate-300 rounded-lg text-left flex flex-col items-center"> */}
			<form name="login" className="w-11/12 lg:w-4/5 mx-auto p-6 border border-slate-300 rounded-lg text-left flex flex-col items-center" method="POST" data-netlify="true" netlify-honeypot="bot-field">
				{/* Required for Netlify serverless forms */}
				<input type="hidden" name="form-name" value ="login" />
				{/* Required for Netlify serverless forms */}
					
				<div className="mb-4 w-full">
				  <label htmlFor="contactInfo" className="block text-sm font-medium mb-2">Account Email</label>
				  <input
					type="text"
					id="contactInfo"
					name="email"
					placeholder='Email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className="w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
					required
				  />
				</div>

				<button
				  type="submit"
				  className="inline-block md:block md:w-2/5 p-3 bg-blue-500 hover:bg-blue-600 rounded text-white font-semibold"
				>
				  Submit
				</button>
			  </form>
			</div>
	    {openFramePath && (
			<Frame
			  htmlPath={openFramePath}
			  isOpen={!!openFramePath}
			  onClose={closeFrame}
			/>
		  )}
		  {currentImage && (
			<div className='absolute top-0 left-0 w-[30%] h-full flex flex-col items-center text-center pb-6 pointer-events-none'>
			  <img
				src={currentImage}
				alt="Previous image"
				className={`max-w-xs mt-4 transition-opacity duration-1000 ease-in-out ${overlayOpacity === 1 ? 'opacity-100' : 'opacity-0'}`}
			  />
			</div>
		  )}
		</div>
	)
}

export default Login