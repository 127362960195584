import React from 'react'
//import {Link , useNavigate} from 'react-router-dom'
import {Link } from 'react-router-dom'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

function Navbar() {

	return (
		<nav className='flex justify-around items-center p-1 md:p-3 border-b-2 border-b-slate-700 bg-slate-500 text-slate-950 '>
		 <div className='w-[50%] min-w-fit min-h-[9vh] md:min-h-[17vh] flex items-end'>
			<Link to='/'><img src="/BlueMugLogo_Clear.png" alt="Blue Mug Logo" className="transition-all navbar-logo max-w-xs max-h-20 md:max-h-40 absolute left-0 top-0 pl-0 md:static" /></Link>
			<span className='relative md:static left-0 bottom-0 bluemug-stroke md:text-shadow-none font-semibold text-l sm:text-xl md:text-3xl text-inter transform md:-translate-x-24' >
				Blue Mug Data Analysis
			</span>
		</div>
		<div className='w-[50%] '>
			<ul className ='flex justify-end p-2 md:p-6 gap-6 font-semibold transition duration-200 ease-in-out'>

				<Popover className="relative text-base text-sm sm:text-base md:text-lg hover:text-gray-600 hover:underline">
				  <PopoverButton>About</PopoverButton>
				  <PopoverPanel anchor="bottom" className="flex flex-col bg-slate-200/60 rounded px-1 md:bg-transparent">
					<a href="/" className="text-sm md:text-base hover:text-gray-600 hover:underline">About the Company</a>
					<a href="/team" className="text-sm md:text-base hover:text-gray-600 hover:underline">Meet the Team</a>
					<a href="/portfolio" className="text-sm md:text-base hover:text-gray-600 hover:underline">Project Portfolio</a>
				  </PopoverPanel>
				</Popover>

				<Popover className="relative text-base text-sm sm:text-base md:text-lg hover:text-gray-600 hover:underline">
				  <PopoverButton>Services</PopoverButton>
				  <PopoverPanel anchor="bottom" className="flex flex-col bg-slate-200/60 rounded px-1 md:bg-transparent">
					<a href="/webservices" className="text-sm md:text-base hover:text-gray-600 hover:underline">Online Services</a>
					<a href="/contact" className="text-sm md:text-base hover:text-gray-600 hover:underline">Let's Start Talking!</a>
				  </PopoverPanel>
				</Popover>
				
				<Link to='/login' className="text-base text-sm sm:text-base md:text-lg hover:text-gray-600 hover:underline"><li>Client Log-In</li></Link>						

			</ul>
			</div>
		</nav>
	)
}

export default Navbar