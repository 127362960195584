import React, { useEffect, useState, useRef } from 'react';
import Frame from '../components/Frame';

const Team = ({ currentImage, setCurrentImage }) => {
  const [openFramePath, setOpenFramePath] = useState(null);
  const [pageOpacity, setPageOpacity] = useState(0); // For the new image
  const [overlayOpacity, setOverlayOpacity] = useState(1); // For the currentImage overlay
  const hasMounted = useRef(false); // Track if the component has mounted
 

  // Function to open a frame based on its path
  const openFrame = (path) => (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setOpenFramePath(path);
  };

  // Function to close the currently open frame
  const closeFrame = () => setOpenFramePath(null);

	return (
		<div className='h-full min-h-[83vh] md:min-h-[75vh] flex flex-grow relative'>
		  <div className='w-[30%] flex-shrink-0 bg-blue-100 text-black flex flex-col items-center text-center pb-6'>
			<img
			  src="/_sidebar_mountains.jpg"
			  alt="Mountain ridge"
			  className="border border-slate-700 max-w-xs mt-4"
			/>
		  </div>
		  <div className='min-w-[70%] flex-grow flex-basis-[70%] bg-slate-200 text-black flex flex-col items-center text-center pb-6'>
			<div className='w-11/12 lg:w-3/4 mt-8'>
				<a
					href="#"
					onClick={openFrame("/team_grv_cv.html")}
					className="w-[200px] h-[150px] mx-3"
				  >
					<div className="border border-slate-700 rounded-full hover:bg-slate-300 transition duration-300 ease-in-out flex flex-col lg:flex-row items-center justify-left pb-10 lg:pr-4 lg:pb-0 lg:min-w-[590px]">
					  <img src='/team_grv_headshot.png' alt="Gerrit Verbeek, 2017" className="w-full h-full max-w-[300px] max-h-[300px] flex-shrink-0 rounded-full border border-slate-700" />
					  <span className="text-sm md:text-base flex-grow text-left pl-6 pr-4 py-2 pb-14 lg:pr-10 overflow-hidden"><h2 className="text-lg font-bold">Gerrit Verbeek</h2><br/>Licensed Professional Petroleum Engineer<br/>8-year reservoir engineering career, BP Alaska<br/>2.5-year municipal Planning career, Matanuska-Susitna Borough, AK<br/>16 years of VBA + 8 years of Python coding experience<br/>Extensive GIS and database experience<br/><br/><p className="italic text-center lg:text-left">&gt;&gt;&gt; Click for more details</p></span>
					</div>
				  </a>
			</div>	
		  </div>
		  {openFramePath && (
			<Frame
			  htmlPath={openFramePath}
			  isOpen={!!openFramePath}
			  onClose={closeFrame}
			/>
		  )}
		</div>
	)

};

export default Team;