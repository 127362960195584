export const bulletins = [	
	{ value: '1', text: 'Automation', 		tag:'AUT', cat: 'Filter Projects (Click to Toggle)', themecolor: 'blue', activehex: '#64B4B4', inactivehex:'#CEE9E8',  selected: true },
	{ value: '2', text: 'GIS', 				tag:'GIS', cat: 'Filter Projects (Click to Toggle)', themecolor: 'blue', activehex: '#64B4B4', inactivehex:'#CEE9E8',  selected: true },
	{ value: '3', text: 'Data Analysis', 	tag:'DAN', cat: 'Filter Projects (Click to Toggle)', themecolor: 'blue', activehex: '#64B4B4', inactivehex:'#CEE9E8',  selected: true },
	{ value: '4', text: 'Databases', 		tag:'DBM', cat: 'Filter Projects (Click to Toggle)', themecolor: 'blue', activehex: '#64B4B4', inactivehex:'#CEE9E8',  selected: true },
	{ value: '5', text: 'Data Processing', 	tag:'DPR', cat: 'Filter Projects (Click to Toggle)', themecolor: 'blue', activehex: '#64B4B4', inactivehex:'#CEE9E8',  selected: true },
];	


export function encodeTagList(tagList, tagArray) {
    // Check if inputString length matches tagArray length
	
    if (tagList.length !== tagArray.length) {
        throw new Error('Input string length must match the length of the tag array');
    }

    const outputArray = new Array(tagArray.length).fill('0'); // Initialize with '0's based on tagArray length
    
    tagList.split(',').forEach(tagString => {
        const tag = tagString.trim(); // Remove any extra whitespace
        const foundTag = tagArray.find(t => t.tag === tag);
        if (foundTag) {
            const index = parseInt(foundTag.value) - 1; // Adjust for 0-based index
            outputArray[index] = '1';
        }
    });
    
    return outputArray.join('');
}

export function decodeTagString(inputString, tagArray) {
    // Check if inputString length matches tagArray length
    if (inputString.length !== tagArray.length) {
        throw new Error('Input string length must match the length of the tag array');
    }

    const result = [];
    
    tagArray.forEach(tagInfo => {
        const index = parseInt(tagInfo.value) - 1; // Adjust for 0-based index
        if (inputString[index] === '1') {
            result.push(tagInfo.tag);
        }
    });
    
    return result;
}

	  
	  