import React, { useState, useEffect } from 'react';
import { updateURLParameter } from '../utils/URLParams';
import { bulletins } from '../utils/Tags';  // Import the tags constant and functions

// Object to map URL params to the corresponding arrays
const paramToSourceArray = {
  bulletins
  // Add more mappings here if needed
};

export const CustomSelect = ({ options, multiple = false, urlparam, trackparam = false, onSelectionChange }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    // Initialize selected items based on URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(urlparam);
    console.log(urlparam, param); // Debug log

    if (param) {
      const sourceArray = paramToSourceArray[urlparam] || []; // Get the corresponding array
      const newSelectedItems = [];

      sourceArray.forEach(item => {
        const index = parseInt(item.value) - 1;
        if (param[index] === '1') {
          newSelectedItems.push(item);
        }
      });
      console.log(`Selected items for ${urlparam}:`, newSelectedItems); // Debug log
      setSelectedItems(prevItems => [...prevItems, ...newSelectedItems]);
    } else {
      // Initialize selected items based on options with selected: true
      const initialSelectedItems = options.filter(option => option.selected);
      setSelectedItems(initialSelectedItems);
    }
  }, [urlparam, options]);

  useEffect(() => {
    // Update URL parameter when selectedItems change
    const tagBinaryString = encodeTagsToBinaryString(selectedItems, options);
    if (trackparam) {
      updateURLParameter(urlparam, tagBinaryString);
    }
    // Call the callback function with the selected items
    onSelectionChange && onSelectionChange(selectedItems);
  }, [selectedItems, options, trackparam, urlparam]);

  const handleSelect = (item) => {
    if (multiple) {
      if (selectedItems.includes(item)) {
        handleDeselect(item);
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      setSelectedItems([item]);
    }
  };

  const handleDeselect = (item) => {
    setSelectedItems(selectedItems.filter(selected => selected !== item));
  };

  const categories = [...new Set(options.map(option => option.cat))];

  return (
    <div className="w-3/4 bg-inherit max-w-2xl mx-auto">
      {categories.map(cat => (
        <div key={cat} className={`rounded-lg bg-inherit border-2 p-4 mt-4 mb-4 border-${options.find(option => option.cat === cat)?.themecolor}-800`}>
          <label className="absolute -translate-y-7 font-medium bg-inherit w-fit px-2 text-black">{cat}</label>
          <ul className="flex flex-wrap items-start">
            {options
              .filter(option => option.cat === cat)
              .map(option => (
                <li key={option.value} className="flex p-1">
                  <div
                    className={`select__item flex cursor-pointer ${selectedItems.includes(option) ? 'select__item--selected' : ''} py-1 px-2 rounded-lg ${selectedItems.includes(option) ? `bg-${option.themecolor}-600` : `bg-${option.themecolor}-100`}`}
                    onClick={() => handleSelect(option)}
                  >
                    {option.text}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export function encodeTagsToBinaryString(selectedItems, tags) {
  const outputArray = new Array(tags.length).fill('0');
  
  selectedItems.forEach(selectedItem => {
    outputArray[parseInt(selectedItem.value)-1] = '1';
  });

  return outputArray.join('');
};
